/* If you need to add @import statements, do so up here */


@import "jit-refresh.css"; /* triggers frontend rebuilds */

@import "tailwindcss";
@plugin '@tailwindcss/typography';
/* /\* Set up Tailwind imports *\/ */
@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --body-background: #f2f2f2;
  --body-color: #444;
  --heading-color: black;
  --action-color: blue;
}
@import "emacs.css";
@layer base {
  html {
    font-family: "Victor Mono", system-ui, sans-serif;
  }

}

body {
  background: var(--body-background);
  color: var(--body-color);
  /* font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", */
  /*   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", */
  /*   "Helvetica", "Arial", sans-serif; */
  /* margin: 0 8px; */
  font-size: 108%;
  line-height: 1.5;
}

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: blue;
  @apply underline-offset-4;
}

/* h1 { */
/*   margin: 1rem 0 3rem; */
/*   text-align: center; */
/*   font-weight: 900; */
/*   font-size: 2.5rem; */
/*   color: var(--heading-color); */
/*   line-height: 1.2; */
/* } */

body > header {
  margin: 1rem;
  text-align: center;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

nav {
  @apply p-2 bg-black;
  ul {
    @apply md:flex gap-[1.5rem] my-[2rem] justify-center font-bold text-[1.3rem];
    li {
      @apply my-3 md:my-0;
    }
  }

  a {
    @apply text-gray-300 underline decoration-slate-50;
  }
}

main {
  margin: 2rem auto 4rem;
  max-width: 65rem;
  min-height: calc(100vh - 200px);
  padding: 25px 25px 50px;
  background: white;
  box-shadow: 2px 3px 3px #ddd;
  border-radius: 3px;

  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}

.photography {
  @apply bg-[#333333] text-white;

  main {
    @apply my-0 bg-[#333333] text-white shadow-none max-w-full p-0;
    a {
      @apply text-white;
    }
  }

  .gallery-grid {
    @apply grid grid-cols-5 gap-4;
  }

  .img-card {
    @apply object-cover w-[250px] h-[250px] border border-white;
  }

  .img-full {
    @apply object-cover w-[80%];
  }

  .img-container {
    @apply bg-black flex justify-center items-center w-full;
  }
}

footer {
  text-align: center;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}

h1, h2, h3 {
  @apply font-bold;
}

.btn {
  @apply rounded p-3 m-[1px] text-lg font-semibold shadow-sm border border-indigo-500 hover:bg-gray-300 no-underline;
}

.btn-dark {
  @apply text-white border-none bg-black hover:bg-gray-500;
}

.btn-blue {
  @apply text-white border-none bg-blue-800 hover:bg-blue-500;
}

.btn-light-blue {
  @apply text-white border-none bg-blue-300 hover:bg-blue-100;
}

.btn-red {
  @apply text-white border-none bg-red-500 hover:bg-red-300;
}

.btn-outline-orange {
  @apply text-black border-orange-400 bg-white hover:bg-orange-300;
}

.btn-cwd {
  @apply text-black border-0 bg-gray-300 hover:bg-orange-300;
}

.btn-purple {
  @apply rounded bg-indigo-600 p-3 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600;
}


.category-block {
  @apply text-xs;
}

.tags-block {
  @apply text-xs;
}

.badge-category {
  @apply text-xs text-black bg-green-100 p-1 no-underline;
}

.badge-newsletter {
  @apply !bg-black text-white;
}

.badge-books {
  @apply !bg-white text-black border border-gray-400;

}

.badge-books::before {
  content: "📚 "
}

.badge-photo {
  @apply !bg-gray-100;
}

.badge-tag {
  @apply text-xs text-black bg-blue-100 p-1 no-underline;
}

.isso-thread-heading {
  @apply text-2xl mb-3;
}

.isso-post-action input[type="submit"] {
  @apply bg-green-300;
}

.isso-textarea {
  @apply text-black;
}


.ft-persona {
  @apply rounded-full w-[80px] h-[80px] object-cover ring-2 ring-offset-1 ring-cyan-400;
}

.ft-persona-block {
  @apply bg-gray-200 px-4 flex items-center justify-start gap-4 h-[140px] rounded;
}

.ft-quote-block {
  @apply my-4 py-4 bg-gray-100 px-6 rounded;
}

.ft-picture-block {
    @apply px-4 pt-4 pb-4 bg-black text-xs;

    img {
      @apply mt-0;
    }
    p {
      @apply text-white;
    }

    .ft-picture-info {
      @apply text-gray-600;
    }
}

.ft-img {}


.ft-img-warning {
  @apply border-dotted border-4 border-red-300;
}

.badge {
  perspective: 1000px;
}
.transform-style-preserve-3d {
  transform-style: preserve-3d;
}

.badge-backside-hidden {
     backface-visibility: hidden;
 }


.isso-form {
  @apply p-4 bg-black rounded shadow space-y-2;
}

.isso-input, .isso-textarea {
  @apply w-full p-2 border border-gray-300 rounded-lg;
}

.isso-postbox-header {
  @apply font-semibold text-lg text-gray-800;
}

